<template>
    <div>
        <b-card title="BOTANICANN | SOFTWARE 🚀">
            <b-card-text>Proyecto en desarrollo ...</b-card-text>
        </b-card>
    </div>
</template>

<script>

    import { BCard, BCardText } from 'bootstrap-vue';

    export default {
        components: {
            BCard,
            BCardText
        }
    }

</script>